/* global Common,$,Const */
/**
 * @file 画面共通処理（ページ遷移）
 * @author Ozaki Takao
 */
(function () {
  'use select';
  if (typeof window.Common === 'undefined') {
    window.Common = {};
  }
  /**
   * ページ
   */
  Common.PAGE = {
    // ログイン画面
    LOGIN: 'login',
    // スケジュール管理TOP画面
    SCHEDULE_TOP: 'schedule-top',
    // スケジュール詳細画面
    SCHEDULE_DETAIL: 'schedule-detail',
    // スケジュール配信画面
    SCHEDULE_DELIVERY: 'schedule-delivery',
    // 配信端末選択画面
    SCHEDULE_TERMINAL_SELECT: 'schedule-terminal-select',
    // スケジュールアップロード画面
    SCHEDULE_UPLOAD: 'schedule-upload',
    // 端末操作一覧画面
    TERMINAL_OPERATION_LIST: 'terminal-operation-list',
    // 端末操作画面
    TERMINAL_OPERATION: 'terminal-operation',
    // ホームTOP画面
    HOME_TOP: 'home-top',
    // タスクエラー一覧画面
    TASK_ERROR_LIST: 'task-error-list',
    // 機器異常通知一覧画面
    TERMINAL_FAULT_INFORMATION_LIST: 'terminal-fault-information-list',
    // お知らせ一覧画面
    INFORMATION_LIST: 'information-list',
    // タスク一覧画面
    TASK_LIST: 'task-list',
    // 端末管理TOP画面
    TERMINAL_TOP: 'terminal-top',
    // 個別端末情報画面
    TERMINAL_DETAIL: 'terminal-detail',
    // 端末動作履歴画面
    TERMINAL_OPERATION_HISTORY: 'terminal-operation-history',
    // 端末情報編集画面
    TERMINAL_INFO_EDIT: 'terminal-info-edit',
    // 端末設定画面
    TERMINAL_SETTING: 'terminal-setting',
    // ダイレクト再生リスト画面
    TERMINAL_DIRECT_LIST: 'terminal-direct-list',
    // ダイレクト再生登録・編集画面
    TERMINAL_DIRECT_EDIT: 'terminal-direct-edit',
    // コンテンツファイル追加画面
    TERMINAL_DIRECT_CONTENTS_ADD: 'terminal-direct-contents-add',
    // スケジュールリスト画面
    TERMINAL_SCHEDULE_LIST: 'terminal-schedule-list',
    // コンテンツ管理画面
    TERMINAL_CONTENTS: 'terminal-contents',
    // 店舗グループ管理TOP画面
    STORE_GROUP_TOP: 'store-group-top',
    // 店舗グループ新規追加画面
    STORE_GROUP_ADD: 'store-group-add',
    // 店舗グループ詳細画面
    STORE_GROUP_DETAIL: 'store-group-detail',
    // 店舗グループ情報編集画面
    STORE_GROUP_INFO_EDIT: 'store-group-info-edit',
    // 店舗グループ所属端末移動画面
    STORE_GROUP_TERMINAL_EDIT: 'store-group-terminal-edit',
    // ユーザー管理TOP画面
    USER_TOP: 'user-top',
    // ユーザーグループ新規追加画面
    USER_GROUP_ADD: 'user-group-add',
    // ユーザーグループ詳細画面
    USER_GROUP_DETAIL: 'user-group-detail',
    // ユーザーグループ情報編集画面
    USER_GROUP_INFO_EDIT: 'user-group-info-edit',
    // グループ所属ユーザー一覧画面
    USER_GROUP_USER_LIST: 'user-group-user-list',
    // 所属ユーザーグループ変更画面
    USER_GROUP_TERMINAL_RELATE: 'user-group-terminal-relate',
    // ユーザー新規追加画面
    USER_ADD: 'user-add',
    // ユーザー情報編集画面
    USER_INFO_EDIT: 'user-info-edit',
    // ユーザー詳細画面
    USER_DETAIL: 'user-detail',
    // 端末割当画面
    USER_TERMINAL_ASSIGN: 'user-terminal-assign',
    // グループ所属端末一覧画面
    USER_GROUP_TERMINAL_LIST: 'user-group-terminal-list',
    // 端末新規追加画面
    USER_TERMINAL_ADD: 'user-terminal-add',
    // マスタ管理画面
    MASTER_TOP: 'master-top',
    // メール通知設定画⾯
    TERMINAL_CONTACT_EDIT: 'terminal-contact-edit',
    // 接続機器異常状態履歴画⾯
    TERMINAL_CONTACT_HISTORY: 'terminal-contact-history'
  };
  /**
   * 履歴エントリ処理種別
   */
  Common.HISTORY_ENTRY_PROC_TYPE = {
    PUSH: 'push', // エントリの追加
    REPLACE: 'replace', // エントリの修正
    NONE: 'none' // エントリの処理なし
  };
  /**
   * ページ遷移用クラスの定義とインスタンスの作成
   */
  Common.PageTranIns = (function () {
    var PageTranClass = function () {
      /**
       * 初期表示ページ名
       * @type {string}
       */
      this.firstPage = null;
      /**
       * (個別)ページ遷移処理
       * @type {function}
       */
      this.pageTransitionFunc = null;
      this.init_();
    };
    PageTranClass.prototype = {
      /**
       * 初期処理
       * @access private
       */
      init_: function () {
        var that = this;
        // popstate時（ブラウザバック等）
        $(window).on('popstate', function (e) {
          that.pageTransitionOnWinEvt();
        });
      },
      /**
       * 画面遷移(window関連)
       * @access public
       */
      pageTransitionOnWinEvt: function () {
        var that = this;

        // 履歴エントリに遷移情報がある場合
        var state = window.history.state;
        if (state && state.page) {
          that.pageTransition(state.page, state.param || null, Common.HISTORY_ENTRY_PROC_TYPE.NONE);
          return;
        }

        // 履歴エントリがない場合、設定された初期表示用ページに遷移
        if (that.firstPage) {
          that.pageTransition(that.firstPage, null, Common.HISTORY_ENTRY_PROC_TYPE.REPLACE);
        }
      },
      /**
       * ページ遷移を行う
       * @access public
       * @param {string} to 遷移先画面
       * @param {object} param 遷移先へのパラメータ
       * @param {string} historyEntryProcType 履歴エントリ処理種別(デフォルト：エントリの追加)
       */
      pageTransition: function (to, param, historyEntryProcType) {
        var that = this;
        if (Boolean(to) === false) {
          console.error('to is empty!');
          return;
        }
        if (typeof that.pageTransitionFunc !== 'function') {
          console.error('pageTransitionFunc is empty!');
          return;
        }

        // 履歴エントリの処理
        var stateObj = {
          page: to
        };
        if (typeof param === 'object' && param !== null) {
          stateObj.param = param;
        }
        var lHistoryEntryProcType = historyEntryProcType || Common.HISTORY_ENTRY_PROC_TYPE.PUSH;
        switch (lHistoryEntryProcType) {
          case Common.HISTORY_ENTRY_PROC_TYPE.PUSH:
            // エントリの追加
            window.history.pushState(stateObj, null);
            break;
          case Common.HISTORY_ENTRY_PROC_TYPE.REPLACE:
            // エントリの修正
            window.history.replaceState(stateObj, null);
            break;
          default:
            break;
        }
        // ページ遷移
        that.pageTransitionFunc(to, param);
      },
      /**
       * 初期ページに遷移する
       * @access public
       */
      pageTransitionToFirst: function () {
        var that = this;
        // 設定された初期表示用ページに遷移
        if (that.firstPage) {
          that.pageTransition(that.firstPage);
        }
      }
    };
    return new PageTranClass();
  }());
  /**
   * 初期表示ページの設定
   * @access public
   * @param {string} firstPage 初期表示ページ名
   */
  Common.setFirstPage = function (firstPage) {
    Common.PageTranIns.firstPage = firstPage;
  };
  /**
   * (個別)ページ遷移処理の設定
   * @access public
   * @param {function} pageTransitionFunc ページ遷移処理（＜コールバック＞to: 遷移先, param: パラメーター）
   */
  Common.setPageTransitionFunc = function (pageTransitionFunc) {
    Common.PageTranIns.pageTransitionFunc = pageTransitionFunc;
  };
  /**
   * ページ遷移を行う
   * ※Common.setPageTransitionFunc により、個別処理を事前設定する必要がある。
   * @access public
   * @param {string} to 遷移先画面(必須)
   * @param {object} param 遷移先へのパラメーター
   * @param {string} historyEntryProcType 履歴エントリ処理種別(デフォルト：エントリの追加)
   */
  Common.pageTransition = function (to, param, historyEntryProcType) {
    Common.PageTranIns.pageTransition(to, param, historyEntryProcType);
  };
  /**
   * ページ遷移を行う（画面ロード時）
   * ※Common.setPageTransitionFunc により、個別処理を事前設定する必要がある。
   * @access public
   */
  Common.pageTransitionOnload = function () {
    Common.PageTranIns.pageTransitionOnWinEvt();
  };
  /**
   * 初期ページに遷移する
   * ※Common.setPageTransitionFunc により、個別処理を事前設定する必要がある。
   * @access public
   */
  Common.pageTransitionToFirst = function () {
    Common.PageTranIns.pageTransitionToFirst();
  };
  /**
   * モーダル表示を行う
   * ※Common.setPageTransitionFunc により、個別処理を事前設定する必要がある。
   * @access public
   * @param {string} to 遷移先画面(必須)
   * @param {object} param 遷移先へのパラメーター
   */
  Common.pageTransitionForModal = function (to, param) {
    Common.PageTranIns.pageTransition(to, param, Common.HISTORY_ENTRY_PROC_TYPE.NONE);
  };
  /**
   * システムエラー画面に遷移する
   * @access public
   */
  Common.pageTransitionToSystemerror = function () {
    window.location.href = '/error/system_error.html';
  };
  /**
   * ユーザー種別に紐づくホーム画面に遷移する
   * @access public
   */
  Common.pageTransitionByUserType = function () {
    if (Common.matchSomeUserType([Const.USER_TYPE.ADMIN, Const.USER_TYPE.OPERATION, Const.USER_TYPE.AGENT])) {
      // 管理、運用、設定(代理店)ユーザーの場合
      // ホーム画面に遷移する
      Common.menuTransitionInterMenu(Const.MENU.HOME.PATHNAME);
    } else if (Common.matchSomeUserType([Const.USER_TYPE.GENERAL])) {
      // 使用ユーザーの場合
      // 端末操作一覧画面に遷移する
      Common.menuTransitionInterMenu(Const.MENU.OPERATION.PATHNAME);
    }
  };
  /**
   * メニュー間遷移を行う
   * ※引数の連携なし
   * @access public
   * @param {string} toMenuPathname 遷移先メニュー
   */
  Common.menuTransitionInterMenu = function (toMenuPathname) {
    if (toMenuPathname === window.location.pathname) {
      Common.pageTransitionToFirst();
    } else {
      window.location.href = toMenuPathname;
    }
  };
}());
